const domainList: any = {
  "luqapay.com": {
    domain: "luqapay.com",
    name: "Luqapay",
    logoExists: false,
    logo: "luqapay.png",
    favicon: "luqapay.ico",
  },
  "speedy.io": {
    domain: "speedy.io",
    name: "Speedyteller",
    logoExists: true,
    logo: "speedyteller.svg",
    favicon: "speedyteller.ico",
  },
  "jetoncash.com": {
    domain: "jetoncash.com",
    name: "JetonCash",
    logoExists: false,
    logo: "jetoncash.svg",
    favicon: "jetoncash.ico",
  },
  "fastpayzone.com": {
    domain: "fastpayzone.com",
    name: "fastpayzone",
    logoExists: false,
    logo: "fastpayzone.svg",
    favicon: "fastpayzone.ico",
  },
  "easypaycircle.com": {
    domain: "easypaycircle.com",
    name: "easypaycircle",
    logoExists: false,
    logo: "easypaycircle.svg",
    favicon: "easypaycircle.ico",
  },
  "payeerfield.com": {
    domain: "payeerfield.com",
    name: "payeerfield",
    logoExists: false,
    logo: "payeerfield.svg",
    favicon: "payeerfield.ico",
  },
  "classicpay.net": {
    domain: "classicpay.net",
    name: "classicpay",
    logoExists: false,
    logo: "classicpay.svg",
    favicon: "classicpay.ico",
  },
  "tkbprocessing.com": {
    domain: "tkbprocessing.com",
    name: "tkbprocessing",
    logoExists: true,
    logo: "tkbprocessing.png",
    favicon: "tkbprocessing.ico",
  },
  "angelspay.io": {
    domain: "angelspay.io",
    name: "angelspay",
    logoExists: true,
    logo: "angelspay.png",
    favicon: "angelspay.ico",
  },
  "bankyprocessing.com": {
    domain: "bankyprocessing.com",
    name: "bankyprocessing",
    logoExists: true,
    logo: "bankyprocessing.svg",
    favicon: "banky.ico",
  },
  "jeton.com": {
    domain: "jeton.com",
    name: "Jeton",
    logoExists: false,
    logo: "jeton.svg",
    favicon: "jeton.ico",
  },
  "hofi.app": {
    domain: "hofi.app",
    name: "Hofi",
    logoExists: true,
    logo: "hofi.webp",
    favicon: "hofi.ico",
  },
  "jeton47.com": {
    domain: "jeton.com",
    name: "Jeton",
    logoExists: false,
    logo: "jeton.svg",
    favicon: "jeton.ico",
  },
  "financialhouse.io": {
    domain: "financialhouse.io",
    name: "financialhouse",
    logoExists: false,
    logo: "financialhouse.svg",
    favicon: "financialhouse.ico",
    headerType: "v2",
  },
  "kanky.app": {
    domain: "kanky.app",
    name: "Kanky",
    logoExists: true,
    logo: "kanky.png",
    favicon: "kanky.ico",
  },
};

export default domainList;
